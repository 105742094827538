<template>
  <div class="tinymce_content">
    <!-- 发放金额和成功金额 -->
    <div class="components-input">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/09.png" alt="" />
        <p>代发表</p>
      </div>
    </div>
    <div class="tinymce_money">
      <div class="tinymce_amountMoney">
        <p class="amountMoney_money">发放金额</p>
        <div class="amountMoney_number">
          <img src="../../../assets/bgm/icon1.png" alt="" />
          <div class="amountMoney_text">
            <p>￥{{ details.grantMon }}</p>
            <p>笔数：{{ details.grantNum }}笔</p>
          </div>
        </div>
      </div>
      <div class="tinymce_successMoney">
        <p class="amountMoney_money">成功金额</p>
        <div class="amountMoney_number">
          <img src="../../../assets/bgm/icon1.png" alt="" />
          <div class="amountMoney_text">
            <p>￥{{ details.successMon }}</p>
            <p>笔数：{{ details.successNum }}笔</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 输入框查询 -->
    <div class="tinymce_input">
      <div class="modules">
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">发薪企业：</span>
          <el-select
            v-model="form.humanEntId"
            size="medium"
            placeholder="全部"
            style="width: 60%"
            @change="searchState"
            filterable
          >
            <el-option
              v-for="(item, index) in option"
              :key="index"
              :label="item.entName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">用工企业：</span>
          <el-select
            v-model="form.entId"
            size="medium"
            placeholder="全部"
            style="width: 60%"
            @change="searchStates"
            filterable
          >
            <el-option
              v-for="(item, index) in employmentOption"
              :key="index"
              :label="item.entName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="modules module">
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">审批人名称：</span>
          <el-input
            v-model="form.auditorName"
            size="medium"
            style="width: 60%"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">创建人名称：</span>
          <el-input
            v-model="form.createName"
            size="medium"
            style="width: 60%"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="modules module">
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">提交人名称：</span>
          <el-input
            v-model="form.submitName"
            size="medium"
            style="width: 60%"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">支付人名称：</span>
          <el-input
            v-model="form.payerName"
            size="medium"
            style="width: 60%"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="modules module media">
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">支付时间：</span>
          <el-date-picker
            v-model="payTiem"
            @change="changeDatePayTime"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 70%"
            size="medium"
          >
          </el-date-picker>
        </div>
        <div class="grid-content bg-purple" style="">
          <span style="margin-top: 10px">创建时间：</span>
          <el-date-picker
            v-model="foundTiem"
            @change="changeDate"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 70%"
            size="medium"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="modules modules1">
        <div class="grid-content bg-purple" style="">
          <el-button
            type="primary"
            size="medium"
            @click="inquire"
            style="margin-left: 10px; background: #6591f1"
            ><i class="el-icon-search" style="margin-right: 7px"></i
            >查询</el-button
          >
        </div>
        <div class="grid-content bg-purple" style="">
          <el-button
            @click="reset"
            size="medium"
            style="margin-left: 10px; background: #f7f8fa"
            ><i class="el-icon-refresh-left" style="margin-right: 7px"></i
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <!-- 切换栏 -->
    <div class="tinymce_table">
      <div v-if="numList.length > 0">
        <el-radio-group
          v-model="form.status"
          size="medium"
          @change="changeRadio"
        >
          <el-radio-button :label="null"
            ><span class="choices">全部 /</span
            ><span class="numerical">
              {{ numList[0].num ? numList[0].num : 0 }}</span
            ></el-radio-button
          >
          <el-radio-button :label="0"
            ><i
              class="el-icon-star-on"
              style="color: #faad5a; margin-right: 7px"
            ></i
            ><span class="choices">待提交 /</span
            ><span class="numerical">
              {{ numList[1].num ? numList[1].num : 0 }}</span
            ></el-radio-button
          >
          <el-radio-button :label="1"
            ><i
              class="el-icon-star-on"
              style="color: #ffaeae; margin-right: 7px"
            ></i
            ><span class="choices">待审核 /</span
            ><span class="numerical">
              {{ numList[2].num ? numList[2].num : 0 }}</span
            ></el-radio-button
          >
          <el-radio-button :label="2"
            ><i
              class="el-icon-star-on"
              style="color: #febaff; margin-right: 7px"
            ></i
            ><span class="choices">待支付 /</span
            ><span class="numerical">
              {{ numList[3].num ? numList[3].num : 0 }}</span
            ></el-radio-button
          >
          <el-radio-button :label="3"
            ><i
              class="el-icon-star-on"
              style="color: #6591f1; margin-right: 7px"
            ></i
            ><span class="choices">已支付 /</span
            ><span class="numerical">
              {{ numList[4].num ? numList[4].num : 0 }}</span
            ></el-radio-button
          >
          <el-radio-button :label="4"
            ><i
              class="el-icon-star-on"
              style="color: #e84b4d; margin-right: 7px"
            ></i
            ><span class="choices">已驳回 /</span
            ><span class="numerical">
              {{ numList[5].num ? numList[5].num : 0 }}</span
            ></el-radio-button
          >
        </el-radio-group>
      </div>
      <!-- 右边按纽栏 -->
      <div>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="newShow"
          size="medium"
          style="background: #6591f1"
          v-if="
            $store.state['user']['PermissionCodeList'].includes(
              'createPayrollTable'
            )
          "
          >创建代发表</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="educeDerice"
          icon="el-icon-edit-outline"
          style="background: #6591f1"
          v-if="
            $store.state['user']['PermissionCodeList'].includes(
              'deriveSalaryPaymentBehalf'
            )
          "
          >代发表导出</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="deriveTimeShow"
          icon="el-icon-edit-outline"
          style="background: #6591f1"
          v-if="
            $store.state['user']['PermissionCodeList'].includes(
              'deriveIssuingFormDetailsBehalf'
            )
          "
          >导出明细</el-button
        >
      </div>
    </div>

    <!-- 表单 -->
    <div class="tinymce_from">
      <el-table
        :data="pageList.list"
        style="width: 100%"
        v-loading="loadingTable"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="small"
      >
        <el-table-column
          prop="salaryName"
          label="代发表名称"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="humanEntName"
          label="发薪企业"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="entName"
          label="用工企业"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="代发表状态"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
          <template slot-scope="{ row }">
            <div v-if="row.status === null">-</div>
            <div>
              <el-tag type="info" size="small" v-if="row.status === 0"
                >待提交</el-tag
              >
              <el-tag type="warning" size="small" v-if="row.status === 1"
                >待审核</el-tag
              >
              <el-tag size="small" v-if="row.status === 2">待支付</el-tag>
              <el-tag type="success" size="small" v-if="row.status === 3"
                >已支付<span
                  style="color: red"
                  v-if="row.toBePaid < row.grantNum"
                  >-有异常</span
                >
              </el-tag>
              <el-tag type="danger" size="small" v-if="row.status === 4"
                >已驳回</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人姓名"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="submitName"
          label="提交人姓名"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
          <template slot-scope="{ row }">
            <span v-if="row.submitName !== null">
              <span>{{ row.submitName }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="auditorName"
          label="审批人姓名"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
          <template slot-scope="{ row }">
            <span v-if="row.auditorName !== null">
              <span>{{ row.auditorName }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="payerName"
          label="支付人姓名"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
          <template slot-scope="{ row }">
            <span v-if="row.payerName !== null">
              <span>{{ row.payerName }}</span>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="grantNum"
          label="发放总人数"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="grantMon"
          label="发放总金额"
          align="center"
          show-overflow-tooltip
          width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="amountMon"
          label="发放成功金额"
          align="center"
          show-overflow-tooltip
          width="140px"
        >
        </el-table-column>
        <el-table-column
          prop="failMon"
          label="发放失败金额"
          align="center"
          show-overflow-tooltip
          width="140px"
        >
        </el-table-column>
        <el-table-column
          label="支付成功状态"
          align="center"
          show-overflow-tooltip
          width="140px"
        >
          <template slot-scope="{ row }">
            <p>({{ row.toBePaid }} / {{ row.grantNum }})</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="200">
          <template slot-scope="{ row }">
            <span
              v-if="
                $store.state['user']['PermissionCodeList'].includes(
                  'submitSalaryPaymentBehalf'
                )
              "
            >
              <el-tag
                style="margin-left: 5px; cursor: pointer"
                size="small"
                @click="relevancyShow(row)"
                v-if="
                  (row.status === 0 && row.grantNum !== 0) ||
                  (row.status === 4 && row.grantNum !== 0)
                "
                >提交</el-tag
              >
            </span>
            <span
              v-if="
                $store.state['user']['PermissionCodeList'].includes(
                  'toExamineSalaryPaymentBehalf'
                )
              "
            >
              <el-tag
                style="margin-left: 5px; cursor: pointer"
                @click="configurationshow(row)"
                size="small "
                v-if="row.status == 1"
                >审核</el-tag
              >
            </span>
            <span
              v-if="
                $store.state['user']['PermissionCodeList'].includes(
                  'paymentBehalf'
                )
              "
            >
              <el-tag
                style="margin-left: 5px; cursor: pointer"
                @click="paymentShow(row)"
                v-if="row.status == 2"
                size="small "
                >支付</el-tag
              >
            </span>
            <el-tag
              style="margin-left: 5px; cursor: pointer"
              @click="detil(row)"
              size="small "
              >详情</el-tag
            >
            <span v-if="row.toBePaid == 0">
              <el-tag
                style="margin-left: 5px; cursor: pointer"
                size="small "
                type="danger"
                @click="deleteData(row)"
                v-if="
                  row.status === 0 ||
                  row.status === 1 ||
                  row.status === 2 ||
                  row.status === 4
                "
                >删除</el-tag
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>
          共{{ this.pageList.total }} 条记录 第 {{ this.pageList.pageNum }} 页
        </p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :page-size="form.pageSize"
          :current-page="form.pageIndex"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="pageList.total"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="创建代发表"
        :visible.sync="dialogShowAudit"
        width="540px"
        class="pop-up"
        :before-close="newClose"
      >
        <p class="line"></p>
        <div class="popContent">
          <el-form
            ref="forms"
            :model="forms"
            :rules="rules"
            label-width="130px"
          >
            <el-form-item label="代发表名称:" prop="salaryName">
              <el-input
                v-model="forms.salaryName"
                placeholder="请输入内容"
                maxlength="50"
                size="medium"
              ></el-input>
            </el-form-item>
            <el-form-item label="发薪企业：" prop="humanEntId">
              <el-select
                v-model="forms.humanEntId"
                size="medium"
                placeholder="全部"
                style="width: 100%"
                @change="searchStateAdd"
                filterable
              >
                <el-option
                  v-for="(item, index) in optionAdd"
                  :key="index"
                  :label="item.entName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用工企业：" prop="entId">
              <el-select
                v-model="forms.entId"
                size="medium"
                placeholder="全部"
                style="width: 100%"
                @change="searchStatesAdd"
                filterable
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.entName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账户:">
              <span>{{ this.account.subAccount }} </span>
            </el-form-item>
            <el-form-item label="账户余额:">
              <span>{{ this.account.balance }} 元</span>
            </el-form-item>
          </el-form>
        </div>
        <p class="lines"></p>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="newEnterprise"
            :disabled="setUp"
            style="background: #6591f1"
            >创建</el-button
          >
          <el-button size="medium" @click="newClose">取消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="提交审核"
        :visible.sync="relevancy"
        width="540px"
        class="pop-up"
        :before-close="relevancyClose"
      >
        <p class="line"></p>
        <div class="popContent">
          <el-form ref="forms" label-width="130px">
            <el-form-item label="代发表名称:">
              <span>{{ companyDetails.salaryName }}</span>
            </el-form-item>
            <el-form-item label="发薪企业：">
              <span>{{ companyDetails.humanEntName }}</span>
            </el-form-item>
            <el-form-item label="用工企业：">
              <span>{{ companyDetails.entName }}</span>
            </el-form-item>
            <el-form-item label="用工企业账户余额:">
              <span>{{ this.account.balance }} 元</span>
            </el-form-item>
            <el-form-item label="代发总人数：">
              <span>{{ paidList.grantNum }} 人</span>
            </el-form-item>
            <el-form-item label="代发总金额：">
              <span>{{ paidList.grantMon }} 元</span>
            </el-form-item>
            <el-form-item label="说明:">
              <el-input
                type="textarea"
                :rows="6"
                maxlength="30"
                show-word-limit
                placeholder="请输入内容"
                v-model="forms.remarks"
                style="width: 300px"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <p class="lines"></p>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="submit"
            :disabled="present"
            style="background: #6591f1"
            >提交</el-button
          >
          <el-button size="medium" @click="relevancyClose">取消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="审核"
        :visible.sync="deploy"
        width="540px"
        class="pop-up"
        :before-close="configurationClose"
      >
        <p class="line"></p>
        <div class="popContent">
          <el-form ref="forms" label-width="130px">
            <el-form-item label="代发表名称:">
              <span>{{ companyDetails.salaryName }}</span>
            </el-form-item>
            <el-form-item label="发薪企业：">
              <span>{{ companyDetails.humanEntName }}</span>
            </el-form-item>
            <el-form-item label="用工企业：">
              <span>{{ companyDetails.entName }}</span>
            </el-form-item>
            <el-form-item label="用工企业账户余额:">
              <span>{{ this.account.balance }} 元</span>
            </el-form-item>
            <el-form-item label="代发总人数：">
              <span>{{ paidList.grantNum }} 人</span>
            </el-form-item>
            <el-form-item label="代发总金额：">
              <span>{{ paidList.grantMon }} 元</span>
            </el-form-item>
          </el-form>
        </div>
        <p class="line"></p>
        <div>
          <el-form ref="forms" label-width="130px">
            <el-form-item label="审核状态:">
              <el-radio-group v-model="radioTreaty">
                <el-radio :label="2">审核通过</el-radio>
                <el-radio :label="4">驳回</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="说明:">
              <el-input
                type="textarea"
                :rows="6"
                maxlength="30"
                show-word-limit
                placeholder="请输入内容"
                v-model="forms.remarks"
                style="width: 300px"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <p class="lines"></p>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="examine"
            :disabled="audit"
            style="background: #6591f1"
            >提交</el-button
          >
          <el-button size="medium" @click="configurationClose">取消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="支付打款"
        :visible.sync="payment"
        width="540px"
        class="pop-up"
        :before-close="paymentClose"
      >
        <p class="line"></p>
        <div class="popContent">
          <el-form
            ref="forms"
            :model="forms"
            :rules="rules"
            label-width="130px"
          >
            <el-form-item label="代发表名称:">
              <span>{{ companyDetails.salaryName }}</span>
            </el-form-item>
            <el-form-item label="发薪企业：">
              <span>{{ companyDetails.humanEntName }}</span>
            </el-form-item>
            <el-form-item label="用工企业：">
              <span>{{ companyDetails.entName }}</span>
            </el-form-item>
            <el-form-item label="用工企业账户余额:">
              <span>{{ this.account.balance }} 元</span>
            </el-form-item>
            <el-form-item label="代发总人数：">
              <span>{{ paidList.grantNum }} 人</span>
            </el-form-item>
            <el-form-item label="代发总金额：">
              <span>{{ paidList.grantMon }} 元</span>
            </el-form-item>
            <el-form-item label="支付密码：" prop="code">
              <el-input
                size="small"
                show-password
                placeholder="请输入支付密码"
                v-model.trim="forms.code"
                style="width: 200px"
              >
              </el-input>
              <!-- <el-button
              style="margin-left: 10px;background: #6591f1"
              @click="send"
              v-if="!isTimerSrop"
              size="small"
              type="primary"
              >获取验证码</el-button
            >
            <el-button  size="small" style="margin-left: 20px" disabled v-else>{{
              msgCodeBtnText
            }}</el-button> -->
            </el-form-item>
            <el-form-item label="支付附言:">
              <el-input
                v-model="forms.remark"
                style="width: 200px"
                type="text"
                placeholder="请输入支付附言"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <p class="lines"></p>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="SubmitAuditPay"
            style="background: #6591f1"
            :disabled="toLoad"
            >立即支付</el-button
          >
          <el-button size="medium" :disabled="toLoadClose" @click="paymentClose">取消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="导出发放明细"
        :visible.sync="deriveTime"
        width="540px"
        class="pop-up"
        :before-close="deriveTimeClose"
      >
        <p class="line"></p>
        <div class="popContent">
          <el-form ref="derive" label-width="140px">
            <el-form-item label="导出范围(支付时间):">
              <el-date-picker
                v-model="derive.time"
                @change="changeDeriveTime"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                style="width: 90%"
                size="medium"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <p class="lines"></p>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="medium"
            @click="ExportDetails"
            style="background: #6591f1"
            >导出</el-button
          >
          <el-button size="medium" @click="deriveTimeClose">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { instance } from "@/utils/request";
import { Encrypt, Decrypt } from "@/utils/secret.js";

export default {
  name: "tinymce-demo",

  data() {
    return {
      loadingTable: false,
      toLoad: false,
      setUp: false,
      present: false,
      audit: false,
      toLoadClose: false,
      form: {
        pageIndex: 1,
        pageSize: 15,
        accountType: "1",
        auditorName: "",
        createName: "",
        entId: null,
        humanEntId: null,
        payerName: "",
        salaryName: "",
        status: null,
        submitName: "",
        startTime: "",
        endTime: "",
        payStartTime: "",
        payEndTime: "",
      },
      details: "",
      pageList: "",
      dialogShowAudit: false,
      deploy: false,
      relevancy: false,
      payment: false,
      companyDetails: "",
      textarea: "",
      radioTreaty: 2,
      // isTimerSrop: false, // 验证码
      // msgCodeBtnText: 0,
      // times:'',
      // time:60,
      timers: null,
      payTiem: "",
      foundTiem: "",
      activeIndex: null,
      account: "",
      chenkeRadio: null,
      forms: {
        entName: "",
        entId: "",
        humanEntId: "",
        humanEntName: "",
        salaryName: "",
        type: 1,
        remarks: "",
        remark: "",
        code: "",
      },
      option: [],
      option1: [],
      options: [],
      optionAdd: [],
      employmentOption: [],
      numList: [],
      timer: null,
      paidList: "",
      deriveTime: false,
      derive: {
        time: "",
        payStartTime: "",
        payEndTime: "",
      },
      PayByZhyzlData: {
        entId: "",
        humanEntId: "",
        id: "",
        salaryDetailsIds: [],
        remark: "",
        code: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      rules: {
        salaryName: [
          {
            required: true,
            message: "请输入工资表名称",
            trigger: ["blur"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入支付密码",
            trigger: ["blur"],
          },
        ],
        entId: [
          {
            required: true,
            message: "请选择用工企业",
            trigger: ["blur"],
          },
        ],
        humanEntId: [
          {
            required: true,
            message: "请选择发薪企业",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.lines();
    this.lines1();
    this.lines2();
    this.lines3();
    this.lines4();
    this.payrollEnterprise();
    this.employmentEnterprises();
    this.payrollEnterpriseAdd();
  },
  methods: {
    //点击导出
    educeDerice() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.toExportExcel();
      }, 500);
    },
    toExportExcel() {
      const { form } = this;
      const {
        pageIndex,
        pageSize,
        accountType,
        auditorName,
        createName,
        entId,
        humanEntId,
        payerName,
        salaryName,
        status,
        submitName,
        startTime,
        endTime,
        payStartTime,
        payEndTime,
      } = form;
      instance({
        method: "POST",
        url: "/v1/xReplaceProvide/getReplaceProvideWrite",
        data: {
          pageIndex,
          pageSize,
          accountType,
          auditorName,
          createName,
          entId,
          humanEntId,
          payerName,
          salaryName,
          status,
          submitName,
          startTime,
          endTime,
          payStartTime,
          payEndTime,
        },
        responseType: "blob",
      }).then((res) => {
        const link = document.createElement("a"); //创建a标签
        let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // response就是接口返回的文件流
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "代发表列表"; // 自定义文件名
        link.click(); // 下载文件
        URL.revokeObjectURL(objectUrl); // 释放内存
      });
    },
    lines() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[0].prepend(lswt_2);
    },
    lines1() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[1].prepend(lswt_2);
    },
    lines2() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[2].prepend(lswt_2);
    },
    lines3() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[3].prepend(lswt_2);
    },
    lines4() {
      var lswt_2 = document.createElement("div");
      lswt_2.id = "lswtColse";
      lswt_2.style.width = "3px";
      lswt_2.style.height = "13px";
      lswt_2.style.position = "absolute";
      lswt_2.style.top = "46px";
      lswt_2.style.background = "#6591F1";
      var element_2 = document.getElementsByClassName("el-dialog__header");
      element_2[4].prepend(lswt_2);
    },
    // 点击筛选切换
    ClickTaskHall(activeIndex) {
      this.activeIndex = activeIndex;
      this.form.status = activeIndex;
      this.getData();
    },
    // 列表详情按钮
    detil(row) {
      this.$router.push({ path: "PublishDetails", query: { id: row.id } });
    },
    //开始日期结束日期
    changeDate() {
      for (let i = 0; i < this.foundTiem.length; i++) {
        this.form.startTime = this.foundTiem[0];
        this.form.endTime = this.foundTiem[1];
      }
    },
    changeDatePayTime() {
      for (let i = 0; i < this.payTiem.length; i++) {
        this.form.payStartTime = this.payTiem[0];
        this.form.payEndTime = this.payTiem[1];
      }
    },
    // 筛选方法
    // 状态
    searchState(val) {
      this.form.humanEntId = val;
      this.getData();
    },
    searchStates(val) {
      this.form.entId = val;
      this.getData();
    },
    getData() {
      this.loadingTable = true;
      setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const { form } = this;
      const {
        pageIndex,
        pageSize,
        accountType,
        auditorName,
        createName,
        entId,
        humanEntId,
        payerName,
        salaryName,
        status,
        submitName,
        startTime,
        endTime,
        payStartTime,
        payEndTime,
      } = form;
      instance({
        method: "post",
        url: "/v1/xReplaceProvide/payReplaceProvideList",
        data: {
          pageIndex,
          pageSize,
          accountType,
          auditorName,
          createName,
          entId,
          humanEntId,
          payerName,
          salaryName,
          status,
          submitName,
          startTime,
          endTime,
          payStartTime,
          payEndTime,
        },
      })
        .then((res) => {
          if (res.success) {
            this.loadingTable = false;
            this.details = res?.data;
            this.pageList = res?.data?.pageInfo;
            this.numList = res?.data?.provideStatusVO;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 查询
    inquire() {
      this.form.pageIndex = 1;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 重置页面
    reset() {
      this.form.auditorName = "";
      this.form.createName = "";
      this.form.entId = null;
      this.form.humanEntId = null;
      this.form.payerName = "";
      this.form.submitName = "";
      this.form.salaryName = "";
      this.form.status = null;
      this.form.startTime = "";
      this.form.endTime = "";
      this.form.payStartTime = "";
      this.form.payEndTime = "";
      this.form.pageIndex = 1;
      this.form.pageSize = 15;
      this.payTiem = "";
      this.foundTiem = "";
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 翻页
    changePage(val) {
      this.form.pageIndex = val;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // f分页
    currentChange(val) {
      this.form.pageSize = val;
      this.form.pageIndex = 1;
      this.getData();
    },
    newShow() {
      this.forms.salaryName = "";
      this.forms.humanEntId = "";
      this.forms.entId = "";
      this.account = "";
      this.options = "";
      this.dialogShowAudit = true;
    },
    newClose() {
      this.dialogShowAudit = false;
      this.$nextTick(() => {
        if (this.$refs.forms) {
          this.$refs.forms.resetFields(); //个人喜爱。clearValidate有时候验证清不掉，具体原因暂时没找到
        }
      });
    },
    relevancyShow(row) {
      this.forms.remarks = "";
      this.companyDetails = row;
      this.companyDetails.grantMon = Number(
        this.companyDetails.grantMon
      ).toFixed(2);
      this.forms.humanEntId = row.humanEntId;
      this.forms.entId = row.entId;
      this.unpaid();
      this.accountBalance();
      this.relevancy = true;
    },
    relevancyClose() {
      this.relevancy = false;
    },
    configurationshow(row) {
      this.forms.remarks = "";
      this.radioTreaty = 2;

      this.companyDetails = row;
      this.companyDetails.grantMon = Number(
        this.companyDetails.grantMon
      ).toFixed(2);
      this.forms.humanEntId = row.humanEntId;
      this.forms.entId = row.entId;
      this.unpaid();
      this.accountBalance();
      this.deploy = true;
    },
    configurationClose() {
      this.deploy = false;
    },
    paymentShow(row) {
      this.forms.code = "";
      this.forms.remark = "";
      this.companyDetails = row;
      this.forms.humanEntId = row.humanEntId;
      this.forms.entId = row.entId;
      this.companyDetails.grantMon = Number(
        this.companyDetails.grantMon
      ).toFixed(2);
      this.unpaid();
      this.accountBalance();
      this.payment = true;
    },
    paymentClose() {
      this.payment = false;
    },
    unpaid() {
      instance({
        method: "post",
        url: "/v1/xReplaceProvide/getDetailsBePaid",
        data: this.companyDetails.id,
      })
        .then((res) => {
          if (res.code == 200) {
            this.paidList = res.data;
            this.paidList.grantMon = this.paidList.grantMon.toFixed(2);
          }
        })
        .catch((err) => {});
    },
    newEnterprise() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.$refs.forms.validate((valid) => {
          if (valid) {
            this.setUp = true;
            setTimeout(() => {
            this.setUp = false;
          }, 60000);
            const enterpriseName = this.option.filter(
              (item) => item.id === this.forms.humanEntId
            );
            const humanResourceEnterpriseName = this.options.filter(
              (item) => item.id === this.forms.entId
            );
            const { forms } = this;
            const { entId, humanEntId, salaryName, type } = forms;
            instance({
              method: "post",
              url: "/v1/xReplaceProvide/createPayroll",
              data: {
                entName: humanResourceEnterpriseName[0].entName,
                humanEntName: enterpriseName[0].entName,
                entId,
                humanEntId,
                salaryName,
                type,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  this.getData();
                  this.newClose();
                  this.dialogShowAudit = false;
                  setTimeout(() => {
                    this.setUp = false;
                }, 1000);
                } else {
                  this.setUp = false;
                }
              })
              .catch((err) => {
                this.setUp = false;
              });
          }
        });
      }, 500);
    },
    payrollEnterprise() {
      instance({
        method: "post",
        url: "/v1/sysEnt/payConfigEnterprises",
      })
        .then((res) => {
          if (res.success) {
            this.option = res.data;
            this.option.unshift({ id: null, entName: "全部" });
          }
        })
        .catch((err) => {});
    },
    payrollEnterpriseAdd() {
      instance({
        method: "post",
        url: "/v1/sysEnt/payConfigEnterprises",
      })
        .then((res) => {
          if (res.success) {
            this.optionAdd = res.data;
          }
        })
        .catch((err) => {});
    },
    employmentEnterprises() {
      instance({
        method: "post",
        url: "/v1/sysEnt/getEntList",
      })
        .then((res) => {
          if (res.success) {
            this.employmentOption = res.data;
            this.employmentOption.unshift({ id: null, entName: "全部" });
          }
        })
        .catch((err) => {});
    },
    employmentEnterprise() {
      instance({
        method: "post",
        url: `/v1/sysEnt/getEmployersEntList/${this.forms.humanEntId}`,
      })
        .then((res) => {
          if (res.success) {
            this.options = res.data;
          }
        })
        .catch((err) => {});
    },
    accountBalance() {
      instance({
        method: "post",
        url: `/v1/pAcctSon/proAccountById/${this.forms.humanEntId}/${this.forms.entId}`,
      })
        .then((res) => {
          if (res.success) {
            this.account = res.data;
            this.account.balance = Number(this.account.balance).toFixed(2);
          }
        })
        .catch((err) => {});
    },
    searchStateAdd(val) {
      this.forms.humanEntId = val;
      this.employmentEnterprise();
    },
    searchStatesAdd(val) {
      this.forms.entId = val;
      this.accountBalance();
    },
    submit() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.present = true;
        setTimeout(() => {
            this.present = false;
          }, 60000);
        instance({
          method: "post",
          url: "/v1/xReplaceProvide/approvalIssuanceBehalf",
          data: {
            id: this.companyDetails.id,
            status: 1,
            remarks: this.forms.remarks,
          },
        })
          .then((res) => {
            if (res.success) {
              this.getData();
              this.relevancyClose();
              this.relevancy = false;
              setTimeout(() => {
                  this.present = false;
                }, 1000);
            } else {
              this.present = false;
            }
          })
          .catch((err) => {
            this.present = false;
          });
      }, 500);
    },
    examine() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.audit = true;
        setTimeout(() => {
            this.audit = false;
          }, 60000);
        instance({
          method: "post",
          url: "/v1/xReplaceProvide/approvalIssuanceBehalf",
          data: {
            id: this.companyDetails.id,
            status: this.radioTreaty,
            remarks: this.forms.remarks,
          },
        })
          .then((res) => {
            if (res.success) {
              this.getData();
              this.configurationClose();
              this.deploy = false;
              setTimeout(() => {
                  this.audit = false;
                }, 1000);
            } else {
              this.audit = false;
            }
          })
          .catch((err) => {
            this.audit = false;
          });
      }, 500);
    },
    //删除列表数据
    deleteData(row) {
      this.$confirm("您确定要删除所选内容吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        instance({
          method: "post",
          url: `/v1/xReplaceProvide/deletePayroll/${row.id}`,
        }).then((res) => {
          if (res.success) {
            this.$message.success("删除成功");
            this.getData();
          }
        });
      });
    },
    changeRadio() {
      this.form.pageIndex = 1;
      this.getData();
    },
    //  // 点击发送短信
    //  send() {
    //   if (this.timers) {
    //        clearTimeout(this.timers);
    //   }
    //   this.timers = setTimeout(() => {
    //     if(Number(this.companyDetails.grantMon) > Number(this.account.balance) || Number(this.companyDetails.grantMon) == 0){
    //      this.$message.error("代发总金额不能大于账户余额且不能等于零！");
    //     }else{
    //       if (
    //         /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/.test(
    //           localStorage.getItem('phone')
    //         )
    //       ) {
    //         this.verificationCode();
    //         this.timeBuild();
    //       } else {
    //         this.$message.error("发送失败");
    //       }
    //     }
    //   }, 500);
    //     },
    //      // 支付 获取验证码
    //     verificationCode() {
    //       instance({
    //         method: "post",
    //         url: "/v1/xReplaceProvide/payPayrollSendCode",
    //         data: {
    //           grantMon: this.companyDetails.grantMon,
    //           grantNum: this.companyDetails.grantNum,
    //           phone:localStorage.getItem('phone'),
    //           salaryName: this.companyDetails.salaryName,
    //         },
    //       })
    //         .then((res) => {
    //           if(res.success){
    //             this.$message.success("发送成功");
    //           }
    //         })
    //         .catch((err) => {});
    //     },
    //     // 读秒倒计时
    //     timeBuild() {
    //       this.timer = setInterval(() => {
    //         if (this.time < 0) {
    //           this.time = 60;
    //           this.isTimerSrop = false;
    //           clearInterval(this.timer);
    //         } else {
    //           this.isTimerSrop = true;
    //           this.msgCodeBtnText = this.time-- + "s";
    //         }
    //       }, 1000);
    //     },
    // 立即支付
    SubmitAuditPay() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        if (
          Number(this.companyDetails.grantMon) > Number(this.account.balance) ||
          Number(this.companyDetails.grantMon) == 0
        ) {
          this.$message.error("代发总金额不能大于账户余额且不能等于零！");
        } else {
          this.CloudDirectlyConnectedsMybank();
          //     instance({
          //       method: "post",
          //       url: "/v1/sysSmsHistory/getVerificationCode",
          //       data: {
          //         phone: localStorage.getItem('phone'),
          //         code:this.forms.code,
          //         smsType:1
          //       },
          //     })
          //       .then((res) => {
          //         if (res.code === 200) {
          //              this.payment = false
          //         }
          //       })
          //       .catch((err) => {});
        }
      }, 500);
    },
    // 平安银行  支付
    CloudDirectlyConnectedsMybank() {
      this.$refs.forms.validate(async (valid) => {
        if (valid) {
          this.$message.warning("正在处理请稍后！");
          this.toLoad = true;
          setTimeout(() => {
            this.toLoad = false;
          }, 60000);
          this.toLoadClose = true;
          setTimeout(() => {
            this.toLoadClose = false;
          }, 60000);
          this.PayByZhyzlData.entId = this.companyDetails.entId;
          this.PayByZhyzlData.id = this.companyDetails.id;
          this.PayByZhyzlData.humanEntId = this.companyDetails.humanEntId;
          this.PayByZhyzlData.salaryDetailsIds = this.paidList.salaryDetailsIds;
          this.PayByZhyzlData.remark = this.forms.remark;
          this.PayByZhyzlData.code = this.forms.code;
          this.forms.code = "";
          // console.log(this.PayByZhyzlData);

          // const PayByZhyzlData = Encrypt(JSON.stringify(this.PayByZhyzlData));
          instance({
            method: "post",
            url: "/v1/xSalaryDetails/singleSalaryPaymentOnBehalf",
            data: {
              ...this.PayByZhyzlData,
            },
          })
            .then((res) => {
              if (res.code == 200) {
                this.getData();
                this.paymentClose();
                this.forms.code = "";
                this.$message.success(res.data);
                this.payment = false;
                setTimeout(() => {
                  this.toLoad = false;
                  this.toLoadClose = false;
                }, 1000);
              } else {
                this.toLoad = false;
                this.toLoadClose = false;
              }
            })
            .catch((err) => {
              this.toLoad = false;
              this.toLoadClose = false;
            });
        }
      });
    },
    changeDeriveTime() {
      this.derive.payStartTime = "";
      this.derive.payEndTime = "";
      if (this.derive.time != null) {
        for (let i = 0; i < this.derive.time.length; i++) {
          this.derive.payStartTime = this.derive.time[0];
          this.derive.payEndTime = this.derive.time[1];
        }
      }
    },
    deriveTimeShow() {
      (this.derive.time = ""),
        (this.derive.payStartTime = ""),
        (this.derive.payEndTime = ""),
        (this.deriveTime = true);
    },
    deriveTimeClose() {
      this.deriveTime = false;
    },
    ExportDetails() {
      if (this.derive.payStartTime) {
        instance({
          method: "post",
          url: "/v1/xReplaceProvide/getReplaceProvideDetailWrite",
          data: {
            payStartTime: this.derive.payStartTime,
            payEndTime: this.derive.payEndTime,
          },
          responseType: "blob",
        })
          .then((res) => {
            if (res.size == 111) {
              this.$message.error("导出日期间隔不可超过90天");
            } else {
              const link = document.createElement("a"); //创建a标签
              let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // response就是接口返回的文件流
              let objectUrl = URL.createObjectURL(blob);
              link.href = objectUrl;
              link.download = "发放明细"; // 自定义文件名
              link.click(); // 下载文件
              URL.revokeObjectURL(objectUrl); // 释放内存
              this.getData();
              this.deriveTimeClose();
            }
          })
          .catch((err) => {});
      } else {
        this.$message.warning("请先选择导出范围");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tinymce_content {
  padding: 20px;
  width: 100%;
  .components-input {
    width: 100%;
    height: 72px;
    background: #ffffff;
    padding: 20px;
    .tinymceSchedule_title {
      width: 100%;
      border-bottom: 1px solid #ececec;
      display: flex;
      height: 40px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        // line-height: 22px;
      }
      p:nth-child(2) {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
  }
}
.tinymce_money {
  width: 100%;
  height: 240px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.tinymce_amountMoney {
  width: 356px;
  height: 174px;
  background: url("../../../assets/bgm/amountImg.png");
  background-size: cover;
  margin-left: 30px;
}
.amountMoney_money {
  width: 56px;
  height: 50px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  margin-left: 20px;
}
.amountMoney_number {
  margin-left: 20px;
  display: flex;
  img {
    width: 54px;
    height: 54px;
    margin-top: 30px;
  }
}
.amountMoney_text {
  margin-left: 10px;
  margin-top: 35px;
  p:first-child {
    width: 59px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  p:last-child {
    height: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
.tinymce_successMoney {
  width: 356px;
  height: 174px;
  background: url("../../../assets/bgm/successimg.png");
  background-size: cover;
  margin-left: 30px;
}
.tinymce_input {
  width: 100%;
  height: 152px;
  background-color: #fff;
  padding: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .modules {
    // width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  // .modules1 {
  //   // width: 5%;
  // }
  // .module {
  //   // width: 24%;
  // }
  .grid-content {
    display: flex;
    // height: 50px;
  }
}
// @media (max-width: 1500px) {
//     .tinymce_input{
//       height: 200px;
//       position: relative;
//       .media{
//        position: absolute;
//        top: 100px;
//       }
//     }
//   }
.tinymce_table {
  width: 100%;
  height: 74px;
  background-color: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .tinymce_star {
    width: 682px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      display: flex;
      cursor: pointer;
      height: 36px;
      p {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
      i {
        margin-top: 5px;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
}
.highlight {
  border-bottom: 3px solid #1074e4;
  // margin-top: 10px;
}
.highbgc {
  color: #1074e4 !important;
}
.tinymce_from {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  /deep/ .el-table {
    thead {
      height: 50px;
      background: #6591f1;
      font-weight: 600;
    }
    .el-table__cell.is-center {
      text-align: left;
    }
    .cell {
      padding-left: 30px;
    }
  }
  /deep/ .el-tag {
    background: rgba(101, 145, 241, 0.12);
    color: #6591f1;
  }
  /deep/ .el-tag--danger {
    background: rgba(232, 75, 77, 0.12);
    color: #e84b4d;
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
.pop-up {
  .line {
    height: 1px;
    background: #ececec;
    margin: 10px 0 30px;
  }
  .lines {
    height: 1px;
    background: #ececec;
  }
  /deep/ .el-dialog__title {
    padding-left: 14px;
    // border-left: #6591F1 3px solid;
    font-size: 16px;
  }
  // .grid-content {
  // }
  /deep/ .el-form-item__label {
    font-weight: 400;
    color: #666666;
  }
  /deep/ .el-button {
    font-size: 12px;
    font-weight: 400;
    color: #999;
  }
  /deep/ .el-button--primary {
    color: #fff;
  }
  .createForm_button {
    /deep/ .el-button--primary {
      font-size: 14px;
    }
  }
  /deep/ .has-gutter {
    .cell {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  /deep/ .el-dialog__body {
    padding: 0px 40px 20px;
  }
  /deep/ .el-dialog__footer {
    text-align: left;
    padding: 10px 40px 40px;
  }
  /deep/ .el-dialog__header {
    padding: 40px 40px 10px;
    .el-dialog__headerbtn {
      top: 40px;
      right: 40px;
    }
  }
  .popContent {
    padding: 0 26px 0 30px;
  }
  .popContents {
    padding: 0px;
    .arguments {
      padding-left: 30px;
      p {
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
      }
    }
    .components-forms {
      padding: 0;
      .pagination {
        margin: 20px 0;
      }
    }
  }
}
/deep/ .el-input__inner {
  background: #f7f8fa;
  border: none;
}
/deep/ .el-range-input {
  background: #f7f8fa;
}
/deep/ .el-radio-button__inner {
  border: none;
  height: 74px;
  line-height: 56px;
  .choices {
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }
  .numerical {
    font-size: 12px;
    color: #666;
    font-weight: 500;
  }
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  box-shadow: none;
  height: 74px;
  line-height: 56px;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: #6591f1 4px solid;
  background: #fff;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border-left: none;
}
</style>
